import { FormRule } from "../../../src/DigitalForm/rule";

export const rules:FormRule[] = [
  // {
  //   "action": "label",
  //   "description": "筆頭者No1"
  // },
  // {
  //   "action": "sync",
  //   "description": "筆頭者である場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_1" },
  //     "value": "refer:mover_name_1"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "mover_name_1" },
  //     { "formID": "moving", "fieldID": "ghost_honseki_name_1" }
  //   ]]
  // },
  // {
  //   "action": "sync",
  //   "description": "筆頭者でない場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_1" },
  //     "value": "refer:is_honseki_name_1"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "is_honseki_name_1" },
  //     { "formID": "moving", "fieldID": "ghost_honseki_name_1" }
  //   ]]
  // },
  {
    "action": "label",
    "description": "本籍地No2"
  },
  {
    "action": "sync",
    "description": "筆頭者が1人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_2" },
      "value": "refer:is_honseki_leader_1"
    },
    "sync": [
      [
      { "formID": "moving", "fieldID": "target_mover_honseki_address_1"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_2" }
      ],
      [
        { "formID": "moving", "fieldID": "mover_honseki_zipcode_1"},
        { "formID": "moving", "fieldID": "shadow_mover_honseki_zipcode_2" }
      ]
    ]
  },
  {
    "action": "sync",
    "description": "本籍地が1人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_2" },
      "value": "refer:target_mover_honseki_address_1"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_1"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_2" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地がこれまでに入力した方と異なる",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_2"},
      "value": "refer:target_mover_honseki_address_2"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_2" },
      { "formID": "moving", "fieldID": "ghost_honseki_address_2" }
    ]]
  },
  {
    "action": "label",
    "description": "本籍地No3"
  },
  {
    "action": "sync",
    "description": "筆頭者が1人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_3" },
      "value": "refer:is_honseki_leader_1"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_1"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_3" }
    ]]
  },
  {
    "action": "sync",
    "description": "筆頭者が2人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_3" },
      "value": "refer:is_honseki_leader_2"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_2"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_3" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地が1人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_3" },
      "value": "refer:target_mover_honseki_address_1"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_1"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_3" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地が2人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_3" },
      "value": "refer:target_mover_honseki_address_2"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_2"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_3" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地がこれまでに入力した方と異なる",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_3"},
      "value": "refer:target_mover_honseki_address_3"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_3" },
      { "formID": "moving", "fieldID": "ghost_honseki_address_3" }
    ]]
  },
  {
    "action": "label",
    "description": "本籍地No4"
  },
  {
    "action": "sync",
    "description": "筆頭者が1人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_4" },
      "value": "refer:is_honseki_leader_1"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_1"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
    ]]
  },
  {
    "action": "sync",
    "description": "筆頭者が2人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_4" },
      "value": "refer:is_honseki_leader_2"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_2"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
    ]]
  },
  {
    "action": "sync",
    "description": "筆頭者が3人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_4" },
      "value": "refer:is_honseki_leader_3"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_3"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地が1人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_4" },
      "value": "refer:target_mover_honseki_address_1"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_1"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地が2人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_4" },
      "value": "refer:target_mover_honseki_address_2"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_2"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地が3人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_4" },
      "value": "refer:target_mover_honseki_address_3"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_3"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地がこれまでに入力した方と異なる",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_4"},
      "value": "refer:target_mover_honseki_address_4"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_4" },
      { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
    ]]
  },
  {
    "action": "label",
    "description": "本籍地No5"
  },
  {
    "action": "sync",
    "description": "筆頭者が1人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_5" },
      "value": "refer:is_honseki_leader_1"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_1"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
    ]]
  },
  {
    "action": "sync",
    "description": "筆頭者が2人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_5" },
      "value": "refer:is_honseki_leader_2"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_2"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
    ]]
  },
  {
    "action": "sync",
    "description": "筆頭者が3人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_5" },
      "value": "refer:is_honseki_leader_3"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_3"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
    ]]
  },
  {
    "action": "sync",
    "description": "筆頭者が4人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_5" },
      "value": "refer:is_honseki_leader_4"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_4"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地が1人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_5" },
      "value": "refer:target_mover_honseki_address_1"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_1"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地が2人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_5" },
      "value": "refer:target_mover_honseki_address_2"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_2"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地が3人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_5" },
      "value": "refer:target_mover_honseki_address_3"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_3"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地が4人目と同じ場合",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_5" },
      "value": "refer:target_mover_honseki_address_4"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "ghost_honseki_address_4"},
      { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
    ]]
  },
  {
    "action": "sync",
    "description": "本籍地がこれまでに入力した方と異なる",
    "condition": {
      "target": { "formID": "moving", "fieldID": "is_honsekichi_same_5"},
      "value": "refer:target_mover_honseki_address_5"
    },
    "sync": [[
      { "formID": "moving", "fieldID": "target_mover_honseki_address_5" },
      { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
    ]]
  },
  // {
  //   "action": "label",
  //   "description": "筆頭者No4"
  // },
  // {
  //   "action": "sync",
  //   "description": "筆頭者である場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_4" },
  //     "value": "refer:mover_name_4"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "mover_name_4" },
  //     { "formID": "moving", "fieldID": "ghost_honseki_name_4" }
  //   ]]
  // },
  // {
  //   "action": "sync",
  //   "description": "戸籍の筆頭者が1人目と同じ",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_4" },
  //     "value": "1"
  //   },
  //   "sync": [
  //     [
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_1" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_4" }
  //     ],
  //     [
  //       { "formID": "moving", "fieldID": "target_mover_honseki_address_1" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
  //     ]
  //   ]
  // },
  // {
  //   "action": "sync",
  //   "description": "戸籍の筆頭者が2人目と同じ",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_4" },
  //     "value": "2"
  //   },
  //   "sync": [
  //     [
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_2" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_4" }
  //     ],
  //     [
  //       { "formID": "moving", "fieldID": "target_mover_honseki_address_2" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
  //     ]
  //   ]
  // },
  // {
  //   "action": "sync",
  //   "description": "戸籍の筆頭者が3人目と同じ",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_4" },
  //     "value": "3"
  //   },
  //   "sync": [
  //     [
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_3" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_4" }
  //     ],
  //     [
  //       { "formID": "moving", "fieldID": "target_mover_honseki_address_3" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
  //     ]
  //   ]
  // },
  // {
  //   "action": "sync",
  //   "description": "戸籍の筆頭者はこれまでに入力した方と異なる",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_4" },
  //     "value": "4"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "is_honseki_name_4" },
  //     { "formID": "moving", "fieldID": "ghost_honseki_name_4" }
  //   ]]
  // },
  // {
  //   "action": "label",
  //   "description": "本籍地No4"
  // },
  // {
  //   "action": "sync",
  //   "description": "本籍地が1人目と同じ場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honsekichi_same_4" },
  //     "value": "1"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "target_mover_honseki_address_1"},
  //     { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
  //   ]]
  // },
  // {
  //   "action": "sync",
  //   "description": "本籍地が2人目と同じ場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honsekichi_same_4" },
  //     "value": "2"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "target_mover_honseki_address_2"},
  //     { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
  //   ]]
  // },
  // {
  //   "action": "sync",
  //   "description": "本籍地が3人目と同じ場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honsekichi_same_4" },
  //     "value": "3"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "target_mover_honseki_address_3"},
  //     { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
  //   ]]
  // },
  // {
  //   "action": "sync",
  //   "description": "本籍地がこれまでに入力した方と異なる",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honsekichi_same_4"},
  //     "value": "4"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "target_mover_honseki_address_4" },
  //     { "formID": "moving", "fieldID": "ghost_honseki_address_4" }
  //   ]]
  // },
  // {
  //   "action": "label",
  //   "description": "筆頭者No5"
  // },
  // {
  //   "action": "sync",
  //   "description": "筆頭者である場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_5" },
  //     "value": "refer:mover_name_5"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "mover_name_5" },
  //     { "formID": "moving", "fieldID": "ghost_honseki_name_5" }
  //   ]]
  // },
  // {
  //   "action": "sync",
  //   "description": "戸籍の筆頭者が1人目と同じ",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_5" },
  //     "value": "1"
  //   },
  //   "sync": [
  //     [
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_1" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_5" }
  //     ],
  //     [
  //       { "formID": "moving", "fieldID": "target_mover_honseki_address_1" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
  //     ]
  //   ]
  // },
  // {
  //   "action": "sync",
  //   "description": "戸籍の筆頭者が2人目と同じ",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_5" },
  //     "value": "2"
  //   },
  //   "sync": [
  //     [
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_2" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_5" }
  //     ],
  //     [
  //       { "formID": "moving", "fieldID": "target_mover_honseki_address_2" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
  //     ]
  //   ]
  // },
  // {
  //   "action": "sync",
  //   "description": "戸籍の筆頭者が3人目と同じ",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_5" },
  //     "value": "3"
  //   },
  //   "sync": [
  //     [
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_3" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_5" }
  //     ],
  //     [
  //       { "formID": "moving", "fieldID": "target_mover_honseki_address_3" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
  //     ]
  //   ]
  // },
  // {
  //   "action": "sync",
  //   "description": "戸籍の筆頭者が4人目と同じ",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_5" },
  //     "value": "4"
  //   },
  //   "sync": [
  //     [
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_4" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_name_5" }
  //     ],
  //     [
  //       { "formID": "moving", "fieldID": "target_mover_honseki_address_4" },
  //       { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
  //     ]
  //   ]
  // },
  // {
  //   "action": "sync",
  //   "description": "戸籍の筆頭者はこれまでに入力した方と異なる",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honseki_leader_same_5" },
  //     "value": "5"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "is_honseki_name_5" },
  //     { "formID": "moving", "fieldID": "ghost_honseki_name_5" }
  //   ]]
  // },
  // {
  //   "action": "label",
  //   "description": "本籍地No5"
  // },
  // {
  //   "action": "sync",
  //   "description": "本籍地が1人目と同じ場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honsekichi_same_5" },
  //     "value": "1"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "target_mover_honseki_address_1"},
  //     { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
  //   ]]
  // },
  // {
  //   "action": "sync",
  //   "description": "本籍地が2人目と同じ場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honsekichi_same_5" },
  //     "value": "2"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "target_mover_honseki_address_2"},
  //     { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
  //   ]]
  // },
  // {
  //   "action": "sync",
  //   "description": "本籍地が3人目と同じ場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honsekichi_same_5" },
  //     "value": "3"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "target_mover_honseki_address_3"},
  //     { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
  //   ]]
  // },
  // {
  //   "action": "sync",
  //   "description": "本籍地が4人目と同じ場合",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honsekichi_same_5" },
  //     "value": "4"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "target_mover_honseki_address_4"},
  //     { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
  //   ]]
  // },
  // {
  //   "action": "sync",
  //   "description": "本籍地がこれまでに入力した方と異なる",
  //   "condition": {
  //     "target": { "formID": "moving", "fieldID": "is_honsekichi_same_5"},
  //     "value": "5"
  //   },
  //   "sync": [[
  //     { "formID": "moving", "fieldID": "target_mover_honseki_address_5" },
  //     { "formID": "moving", "fieldID": "ghost_honseki_address_5" }
  //   ]]
  // }
]