import {
    sync, conditionalSync, FormElementPath, addressSync, addressSync2
} from '../../../src/digitalForm';

import moving from "../../yokosuka/formData/moving.json";

import residents_certificate from "../../yokosuka/formData/residents_certificate.json";
import seal_registration from "../../yokosuka/formData/seal_registration.json";
import seal_registration_request from "../../yokosuka/formData/seal_registration_request.json";
import mynumber from "../../yokosuka/formData/mynumber.json";
import digital_certificate from "../../yokosuka/formData/digital_certificate.json";
import childcare_allowance_certificate from "../../yokosuka/formData/childcare_allowance_certificate.json";
import pediatric_medical_certificate1 from "../../yokosuka/formData/pediatric_medical_certificate1.json";
import pediatric_medical_certificate2 from "../../yokosuka/formData/pediatric_medical_certificate2.json";

import { DigitalForm } from '../../../src/DigitalForm/DigitalForm';
import { findFieldInput } from '../../../src/DigitalForm/Element/FieldInputContainer';
import { Notifier } from '../../../src/ui/Notifier';

import localize_table from "../../yokosuka/formData/localize_idou.json";

import { rules } from "../../yokosuka/formData/rules";
import { FieldSelector } from '../../../src/DigitalForm/condition';
import { DigitalApplicationDefinition } from '../../../src/DigitalForm/definition/form/DigitalApplicationDefinition';
import { DigitalFormDefinition } from '../../../src/DigitalForm/definition/form/DigitalFormDefinition';
import { DigitalFormTemplate } from '../../../src/DigitalForm/template/DigitalFormTemplate';
import { resolveDigitalFormDefinition } from '../../../src/DigitalForm/template/resolver';
import { formTemplates } from "../../yokosuka/formData/templates";
import { TemplateManager } from "../../../src/DigitalForm/template/TemplateManager";


Object.entries(formTemplates).forEach(entry => {
    TemplateManager.addTemplate(entry[0], entry[1]);
});

export const FormDefinition: DigitalApplicationDefinition = {
    forms: [
        <DigitalFormTemplate>moving,
        <DigitalFormTemplate>residents_certificate,
        <DigitalFormTemplate>seal_registration,
        <DigitalFormTemplate>seal_registration_request,
        <DigitalFormTemplate>mynumber,
        <DigitalFormTemplate>digital_certificate,
        
        <DigitalFormTemplate>pediatric_medical_certificate1,
        <DigitalFormTemplate>childcare_allowance_certificate,
        // <DigitalFormDefinition>pediatric_medical_certificate2
    ].map(template => resolveDigitalFormDefinition(template)),
    extraSetup: (formMap: { [keys: string]: DigitalForm }, definitionMap: { [keys: string]: DigitalFormDefinition }) => {
        const { 
            moving,
            residents_certificate,
            seal_registration,
            seal_registration_request,
            mynumber,
            digital_certificate,
            childcare_allowance_certificate,
            pediatric_medical_certificate1,
            // pediatric_medical_certificate2
         } = formMap;


         //職員側環境を隠す
        //boxにadminプロパティをもっていたらhiddenにしてあげるだけ。trueかどうかは見ていない
        Object.keys(definitionMap).forEach(id => {
            const form = formMap[id];
            definitionMap[id].boxes.forEach(boxDefinition => {
                if (boxDefinition.admin) {
                    const box = findFieldInput(form, boxDefinition.id);
                    box.disabled = true;
                    box.hidden = true;
                }
            });
        });
        //手続きの種類ボックスを隠すぜ
        if(moving){
            const procedure_type_box = findFieldInput(moving, `procedure_type_box`);
            procedure_type_box.hidden = true;
        }

        //無理やり値をセットする
        function forceSetValue(form,id,value){
            if(form){ 
                const target = findFieldInput(form, id);
                target.value = value;
            }
        }
        //プロパティのhiddenを使うと色々影響してくるのでcssで消す
        //display:noneは既に使われていたので
        function setForceHidden(form,id,needDisabaled){
            const target = findFieldInput(form, id);
            target.element.classList.add("force-hidden");
            if(needDisabaled) target.disabled = true;
        }
        function removeForceHidden(form,id,needDisabaled){
            //hiddenを使えない時用
            const target = findFieldInput(form, id);
            // target.element.removeAttribute("style");
            target.element.classList.remove("force-hidden");
            if(needDisabaled) target.disabled = false;
        }


        //ローカライズ初期化
        if(moving){
        let localized_doms=[];

         function initLocalize(){
             Object.keys(localize_table).forEach( key => {
                 localize_table[key].forEach(elem => {
                     const form = moving;
                     // console.log(elem.id);
                     const target_field = findFieldInput(form, elem.id);
                     const target_dom = target_field.element.querySelector(`[data-role=${elem.role}]`);
                     elem.dom = target_dom;
                     localized_doms.push(target_dom);
                     if(!target_dom.querySelector(".condition-origin")){
                         const alt_node = document.createElement("span");
                         alt_node.classList.add("condition-origin","show");
                         alt_node.textContent = target_dom.textContent;
                         target_dom.textContent ="";
                         target_dom.appendChild(alt_node);
                     }
                     const condition_node = document.createElement("span");
                     condition_node.textContent = elem.text;
                     condition_node.classList.add(`condition-${key}`);
                     target_dom.appendChild(condition_node);
                 });
             });
         }
         initLocalize();

        //手続きの種類によって書き換える
        const procedure_type = findFieldInput(moving, "procedure_type");
        procedure_type.virtualInput.addEventListener("change",()=>{
            const no_need_honseki_and_foreginer = ["2","3","4","9","10","11","12","14","15"];
            if(procedure_type.value === "3"){
                for (let i = 1; i <= 5; i++) {
                    setForceHidden(moving,`mover_honseki_${i}`,true);
                }
                for (let i = 1; i <= 5; i++) {
                    setForceHidden(moving,`nationality_${i}`,true);
                    setForceHidden(moving,`qualification_${i}`,true);
                    setForceHidden(moving,`mover_stay_${i}`,true);
                    setForceHidden(moving,`residence_type_${i}`,true);
                    setForceHidden(moving,`mover_card_${i}`,true);
                    setForceHidden(moving,`period_${i}`,true);
                }
            } else if(no_need_honseki_and_foreginer.includes(procedure_type.value)){
                // mover_honseki_1
                // foreigner_field_1
                for (let i = 1; i <= 5; i++) {
                    setForceHidden(moving,`mover_honseki_${i}`,true);
                    setForceHidden(moving,`foreigner_field_${i}`,true);
                }
            } else {
                for (let i = 1; i <= 5; i++) {
                    removeForceHidden(moving,`mover_honseki_${i}`,true);
                    removeForceHidden(moving,`foreigner_field_${i}`,true);
                }
            }

            //外国籍をお持ちですか質問を消しつつ強制的に質問に答えておく
            //ただし、外国籍かどうかの質問は勝手に答えちゃうので戻らない
            if(procedure_type.value === "5" || procedure_type.value === "8"){                
                for (let i = 1; i <= 5; i++) {
                    forceSetValue(moving,`is_foreigner_${i}`,"yes");
                    setForceHidden(moving,`is_foreigner_${i}`,false);
                }
            } else if(procedure_type.value === "6"){
                for (let i = 1; i <= 5; i++) {
                    forceSetValue(moving,`is_foreigner_${i}`,"no");
                    setForceHidden(moving,`is_foreigner_${i}`,false);
                }
            } else {
                for (let i = 1; i <= 5; i++) {
                    removeForceHidden(moving,`is_foreigner_${i}`,false);
                }
            }

            //ローカライズしたdomの初期化
            //うーんここもうちょっと負荷下げられるような
            localized_doms.forEach( dom =>{
                for (let i = 0; i < dom.children.length; i++) {
                    dom.children[i].classList.remove("show");                    
                }
                dom.querySelector(".condition-origin").classList.add("show");
            });
            //ローカライズ
            const keys = Object.keys(localize_table);
            if(keys.includes(procedure_type.value)){
                localize_table[procedure_type.value].forEach(elem => {
                    elem.dom.querySelector(".condition-origin").classList.remove("show");
                    elem.dom.querySelector(`.condition-${procedure_type.value}`).classList.add("show");
                });
            }
        });

        //1. 異動届
        //引越しをされる方(異動者)の情報
        //異動者の先年月日
        //あとで全員にforする
        
        for (let i = 1; i <= 5; i++) {
            conditionalSync([moving,`is_foreigner_${i}`],"yes",
                [moving,`mover_foreigner_birthdate_${i}`],
                [moving,`mover_birthdate_${i}`]
            );
            conditionalSync([moving,`is_foreigner_${i}`],"no",
                [moving,`mover_national_birthdate_${i}`],
                [moving,`mover_birthdate_${i}`]
            );
        }

        for (let i = 1; i <= 5; i++) {
            const is_foreigner = findFieldInput(moving,`is_foreigner_${i}`);
            is_foreigner.virtualInput.addEventListener("change",()=>{
                const field_title = findFieldInput(moving,`mover_old_name_field_${i}`);
                const field_title_dom = field_title.element.querySelector(`[data-role=title]`);
                const input_title = findFieldInput(moving,`mover_old_name_${i}`);
                const input_title_dom = input_title.element.querySelector(`[data-role=title]`);
                const furigana_title = findFieldInput(moving,`mover_old_kana_${i}`);
                const name_placeholder = input_title.element.querySelector(`fieldset > input`);
                const kana_placeholder = furigana_title.element.querySelector("fieldset > input");
                const has_old_name = findFieldInput(moving,`has_old_name_${i}`);
                const has_old_name_dom = has_old_name.element.querySelector(`[data-role=title]`);
                if(is_foreigner.value === "no"){
                    // field_title_dom.textContent = "旧氏登録している方は入力してください。";
                    has_old_name_dom.textContent = "旧氏を登録していますか。";
                    input_title_dom.textContent = "旧氏";
                    name_placeholder.setAttribute("placeholder","例：横須賀");
                    kana_placeholder.setAttribute("placeholder","例：ヨコスカ");
                } else if((is_foreigner.value === "yes")){
                    // field_title_dom.textContent = "通称名がある方は入力してください。";
                    has_old_name_dom.textContent = "通称名を登録していますか。";
                    input_title_dom.textContent = "通称名";
                    name_placeholder.setAttribute("placeholder","例：横須賀　タロウ");
                    kana_placeholder.setAttribute("placeholder","例：ヨコスカ　タロウ");
                }
            });
        }

        const convertTarget = (selector: FieldSelector): FormElementPath => {
            return [formMap[selector.formID], selector.fieldID];
        }

        rules.forEach(rule => {
            switch (rule.action) {
                case "sync":
                    rule.sync.forEach(syncGroup => {
                        if (rule.condition) {
                            conditionalSync(convertTarget(rule.condition.target), rule.condition.value, 
                                ...syncGroup.map(syncRule => convertTarget(syncRule))
                            );
                        } else {
                            sync(...syncGroup.map(syncRule => convertTarget(syncRule)));
                        }
                    });
                    return;
                case "label":
                    return;
            }
            throw new Error(`invalid rule: ${rule}`);
        });

        }
        //住所の同期        
        function addressSyncer(form,target){
            target.forEach( address =>{
                addressSync([form,`target_${address}`],
                    [form, address],
                    [form, `${address}_chouban`],
                    [form, `${address}_building`]
                );
            });
        }
        //本籍の同期
        function addressSyncer2(form,target){
            target.forEach( address =>{
                addressSync2([form,`target_${address}`],
                    [form, address],
                    [form, `${address}_chouban`]
                );
            });
        }
        //1異動届
        if(moving){
        addressSyncer(moving,[
            "agent_address",
            "agent_different_address",
            "agent_different_address2",
            "ghost_premoved_address",
            "ghost_postmoved_address",
            "new_address",
            "old_address",
            "old_setting_address",
            "no_report_address"
        ]);
        addressSyncer2(moving,[
            "mover_honseki_address_1",
            "mover_honseki_address_2",
            "mover_honseki_address_3",
            "mover_honseki_address_4",
            "mover_honseki_address_5"
        ]);
        }
        // 2住民票請求書
        if(residents_certificate){
        addressSyncer(residents_certificate,[
            "listed_address",
            "user_address",
            "applicant_address"
        ]);
        }
        //3、印鑑登録申請書
        if(seal_registration){
        addressSyncer(seal_registration,[
            "register_address",
            "applicant_address",
            "guarantor_address"
        ]);
        }
        //4、印鑑証明交付申請書
        if(seal_registration_request){
        addressSyncer(seal_registration_request,[
            "register_address",
            "applicant_address"
        ]);
        }
        // 5個人番号
        if(mynumber){
        addressSyncer(mynumber,[
            "applicant_address",
            "before_address",
            "agent_address"
        ]);
        }

        // 6電子証明関係届出書
        if(digital_certificate){
        addressSyncer(digital_certificate,[
            "applicant_address",
            "agent_address"
        ]);
        }
        //7児童手当
        if(childcare_allowance_certificate){
            addressSyncer(childcare_allowance_certificate,[
                "former_beneficialy_address",
                "applicant_address",
                "partner_address"
            ]);
            conditionalSync([childcare_allowance_certificate,"same_partner_january_address"],"yes",
                [childcare_allowance_certificate,"address_on_Jan_1"],
                [childcare_allowance_certificate,"target_partner_address_on_Jan_1"]
            );
            conditionalSync([childcare_allowance_certificate,"same_partner_january_address"],["no", null],
                [childcare_allowance_certificate,"partner_address_on_Jan_1"],
                [childcare_allowance_certificate,"target_partner_address_on_Jan_1"]
            );
        }
        //8小児医療
        if(pediatric_medical_certificate1){
        addressSyncer(pediatric_medical_certificate1,[
            "father_address",
            "mother_address",
            "child_address_1",
            "child_address_2",
            "child_address_3"
        ]);
        // unction addressSyncer2(form,target){
        //     target.forEach( address =>{
        //         addressSync2([form,`target_${address}`],
        //             [form, address],
        //             [form, `${address}_chouban`]
        //         );
        //     });
        // }
        addressSync2([pediatric_medical_certificate1,`target_father_address2`],
            [pediatric_medical_certificate1, "father_address"],
            [pediatric_medical_certificate1, "father_address_chouban"]
        );
        addressSync2([pediatric_medical_certificate1,`target_mother_address2`],
            [pediatric_medical_certificate1, "mother_address"],
            [pediatric_medical_certificate1, "mother_address_chouban"]
        );

        }

        //同期

        const isNewSync = true;

        window.addEventListener("didProcedureTypeSet",function didProcedureTypeSet(e){
            // const procedure_type = findFieldInput(moving, "procedure_type");
            // console.log(procedure_type.value);
            console.log("Sync Set");
            if(isNewSync){
                newSync();
            } else {
                oldSync();
            }
            //edgeでonceが使えるか怪しいので
            e.currentTarget.removeEventListener(e.type, didProcedureTypeSet);
        });


        function oldSync(){
            // ■２：【1.異動届】の「7.引っ越し先の世帯情報」の「引っ越し先の住所」（帳票では「新しい住所」）を下記へ反映
            //【1.異動届】の「7.引っ越し先の世帯情報」の「引っ越し先の住所」（帳票では「新しい住所」）を【5.個人番号カード】の「3.申請者の情報」の住所にも反映してください。
            //郵便番号
            sync(
                [moving,"new_zipcode"],
                [seal_registration,"register_zipcode"],
                [seal_registration_request,"register_zipcode"],
                [digital_certificate,"applicant_zipcode"],
                [childcare_allowance_certificate,"applicant_zipcode"],
                [pediatric_medical_certificate1,"father_zipcode"],
                [mynumber,"applicant_zipcode"]
            );
            //都道府県・市区町村・町名
            sync(
                [moving,"new_address"],
                [seal_registration,"register_address"],
                [seal_registration_request,"register_address"],
                [digital_certificate,"applicant_address"],
                [childcare_allowance_certificate,"applicant_address"],
                [pediatric_medical_certificate1,"father_address"],
                [mynumber,"applicant_address"]
            );
            //字名丁目・番地・号など
            sync(
                [moving,"new_address_chouban"],
                [seal_registration,"register_address_chouban"],
                [seal_registration_request,"register_address_chouban"],
                [digital_certificate,"applicant_address_chouban"],
                [childcare_allowance_certificate,"applicant_address_chouban"],
                [pediatric_medical_certificate1,"father_address_chouban"],
                [mynumber,"applicant_address_chouban"]
            );
            //建物名（方書）
            sync(
                [moving,"new_address_building"],
                [seal_registration,"register_address_building"],
                [seal_registration_request,"register_address_building"],
                [digital_certificate,"applicant_address_building"],
                [childcare_allowance_certificate,"applicant_address_building"],
                [pediatric_medical_certificate1,"father_address_building"],
                [mynumber,"applicant_address_building"]
            );

            //■３：異動届の【4.届出人の情報】で届出人が「代理人」を選択して入力した代理人の住所を下記へ反映
            //郵便番号
            sync(
                [moving,"agent_zipcode"],
                [residents_certificate,"applicant_zipcode"],
                [seal_registration,"applicant_zipcode"],
                [seal_registration_request,"applicant_zipcode"],
                [mynumber,"agent_zipcode"],
                [digital_certificate,"agent_zipcode"]
            );
            //都道府県・市区町村・町名
            sync(
                [moving,"agent_address"],
                [residents_certificate,"applicant_address"],
                [seal_registration,"applicant_address"],
                [seal_registration_request,"applicant_address"],
                [mynumber,"agent_address"],
                [digital_certificate,"agent_address"]
            );
            //字名丁目・番地・号など
            sync(
                [moving,"agent_address_chouban"],
                [residents_certificate,"applicant_address_chouban"],
                [seal_registration,"applicant_address_chouban"],
                [seal_registration_request,"applicant_address_chouban"],
                [mynumber,"agent_address_chouban"],
                [digital_certificate,"agent_address_chouban"]
            );
            //建物名（方書）
            sync(
                [moving,"agent_address_building"],
                [residents_certificate,"applicant_address_building"],
                [seal_registration,"applicant_address_building"],
                [seal_registration_request,"applicant_address_building"],
                [mynumber,"agent_address_building"],
                [digital_certificate,"agent_address_building"]
            );
            sync(
                [moving, "notification_date"],
                [residents_certificate, "notification_date"],
                [seal_registration, "notification_date"],
                [seal_registration_request,"notification_date"],
                [mynumber,"notification_date"],
                [digital_certificate,"notification_date"],
                [childcare_allowance_certificate,"notification_date"],
                [pediatric_medical_certificate1,"notification_date"]
            );
        }

        //斎藤さん同期
        
        function seal2seal(){
            //登録申請者の名前
            sync(
                [seal_registration,"register_name"],
                [seal_registration_request,"register_name"]
            );
            //登録申請者のカナ
            sync(
                [seal_registration,"register_kana"],
                [seal_registration_request,"register_kana"]
            );
            //登録申請者の生年月日
            sync(
                [seal_registration,"register_birthdate"],
                [seal_registration_request,"register_birthdate"]
            );
            //窓口に来た人と登録者との関係
            sync(
                [seal_registration,"is_same_register"],
                [seal_registration_request,"is_same_register"]
            );
            //窓口に来た人の名前(フリガナ)
            sync(
                [seal_registration,"applicant_kana"],
                [seal_registration_request,"applicant_kana"]
            );
            //窓口に来た人の住所（申請者）
            // sync(
            //     [seal_registration,"applicant_zipcode"],
            //     [seal_registration_request,"applicant_zipcode"]
            // );
            // sync(
            //     [seal_registration,"applicant_address"],
            //     [seal_registration_request,"applicant_address"]
            // );
            // sync(
            //     [seal_registration,"applicant_address_chouban"],
            //     [seal_registration_request,"applicant_address_chouban"]
            // );
            // sync(
            //     [seal_registration,"applicant_address_building"],
            //     [seal_registration_request,"applicant_address_building"]
            // );
        }

        function childcare2pediatric(){
            //保護者の電話番号
            //未実装

            //保護者の同居関係
            sync(
                [childcare_allowance_certificate,"same_partner_address"],
                [pediatric_medical_certificate1,"same_address_mother"]
            );
            //配偶者の住所
            sync(
                [childcare_allowance_certificate,"partner_zipcode"],
                [pediatric_medical_certificate1,"mother_zipcode"]
            );
            sync(
                [childcare_allowance_certificate,"partner_address"],
                [pediatric_medical_certificate1,"mother_address"]
            );
            sync(
                [childcare_allowance_certificate,"partner_address_chouban"],
                [pediatric_medical_certificate1,"mother_address_chouban"]
            );
            sync(
                [childcare_allowance_certificate,"partner_address_building"],
                [pediatric_medical_certificate1,"mother_address_building"]
            );            
        }

        function newSync(){
            if(moving){
                const procedure_type = findFieldInput(moving, "procedure_type");
                console.log(procedure_type.value);
                switch(procedure_type.value){
                    //転入
                    case "1":{
                        //手続きの種類
                        forceSetValue(mynumber,"type_of_procedure","2");
                        forceSetValue(mynumber,"modify_detail","2");
                        forceSetValue(digital_certificate,"type_of_procedure","1");
                        forceSetValue(pediatric_medical_certificate1,"document_detail","move");
                        //引っ越し後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"],
                            [seal_registration,"register_zipcode"],
                            [seal_registration_request,"register_zipcode"],
                            [mynumber,"applicant_zipcode"],
                            [digital_certificate,"applicant_zipcode"],
                            [childcare_allowance_certificate,"applicant_zipcode"],
                            [pediatric_medical_certificate1,"father_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"],
                            [seal_registration,"register_address"],
                            [seal_registration_request,"register_address"],
                            [mynumber,"applicant_address"],
                            [digital_certificate,"applicant_address"],
                            [childcare_allowance_certificate,"applicant_address"],
                            [pediatric_medical_certificate1,"father_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"],
                            [seal_registration,"register_address_chouban"],
                            [seal_registration_request,"register_address_chouban"],
                            [mynumber,"applicant_address_chouban"],
                            [digital_certificate,"applicant_address_chouban"],
                            [childcare_allowance_certificate,"applicant_address_chouban"],
                            [pediatric_medical_certificate1,"father_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"],
                            [seal_registration,"register_address_building"],
                            [seal_registration_request,"register_address_building"],
                            [mynumber,"applicant_address_building"],
                            [digital_certificate,"applicant_address_building"],
                            [childcare_allowance_certificate,"applicant_address_building"],
                            [pediatric_medical_certificate1,"father_address_building"]
                        );
                        //引っ越し前の住所
                        sync(
                            [moving,"old_zipcode"],
                            [mynumber,"before_zipcode"]
                        );
                        sync(
                            [moving,"old_address"],
                            [mynumber,"before_address"]
                        );
                        sync(
                            [moving,"old_address_chouban"],
                            [mynumber,"before_address_chouban"]
                        );
                        sync(
                            [moving,"old_address_building"],
                            [mynumber,"before_address_building"]
                        );
                        
                        seal2seal();

                        //窓口に来た人の電話番号（代理人）
                        sync(
                            [mynumber,"agent_phone"],
                            [digital_certificate,"agent_phone"]
                        );
                        
                        childcare2pediatric();

                        break;
                    }
                    //転出
                    case "2":{
                        forceSetValue(mynumber,"type_of_procedure","3");
                        //引っ越し前の住所
                        sync(
                            [moving,"old_zipcode"],
                            [residents_certificate,"listed_zipcode"],
                            [mynumber,"applicant_zipcode"]
                        );
                        sync(
                            [moving,"old_address"],
                            [residents_certificate,"listed_address"],
                            [mynumber,"applicant_address"]
                        );
                        sync(
                            [moving,"old_address_chouban"],
                            [residents_certificate,"listed_address_chouban"],
                            [mynumber,"applicant_address_chouban"]
                        );
                        sync(
                            [moving,"old_address_building"],
                            [residents_certificate,"listed_address_building"],
                            [mynumber,"applicant_address_building"]
                        );
                        break;
                    }
                    //転居
                    case "3":{
                        forceSetValue(mynumber,"type_of_procedure","1");
                        forceSetValue(mynumber,"modify_detail","2");
                        forceSetValue(digital_certificate,"type_of_procedure","1");
                        //引っ越し後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"],
                            [seal_registration,"register_zipcode"],
                            [seal_registration_request,"register_zipcode"],
                            [mynumber,"applicant_zipcode"],
                            [digital_certificate,"applicant_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"],
                            [seal_registration,"register_address"],
                            [seal_registration_request,"register_address"],
                            [mynumber,"applicant_address"],
                            [digital_certificate,"applicant_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"],
                            [seal_registration,"register_address_chouban"],
                            [seal_registration_request,"register_address_chouban"],
                            [mynumber,"applicant_address_chouban"],
                            [digital_certificate,"applicant_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"],
                            [seal_registration,"register_address_building"],
                            [seal_registration_request,"register_address_building"],
                            [mynumber,"applicant_address_building"],
                            [digital_certificate,"applicant_address_building"]
                        );

                        seal2seal();
                        
                        //窓口に来た人の電話番号（代理人）
                        sync(
                            [mynumber,"agent_phone"],
                            [digital_certificate,"agent_phone"]
                        );
                        break;
                    }
                    //国外転出
                    case "4":{
                        //引っ越し前の住所
                        sync(
                            [moving,"old_zipcode"],
                            [residents_certificate,"listed_zipcode"]
                        );
                        sync(
                            [moving,"old_address"],
                            [residents_certificate,"listed_address"]
                        );
                        sync(
                            [moving,"old_address_chouban"],
                            [residents_certificate,"listed_address_chouban"]
                        );
                        sync(
                            [moving,"old_address_building"],
                            [residents_certificate,"listed_address_building"]
                        );
                        break;
                    }
                    //国外転入(外国人)
                    case "5":{
                        forceSetValue(pediatric_medical_certificate1,"document_detail","move");
                        //引っ越し後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"],
                            [seal_registration,"register_zipcode"],
                            [seal_registration_request,"register_zipcode"],
                            [childcare_allowance_certificate,"applicant_zipcode"],
                            [pediatric_medical_certificate1,"father_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"],
                            [seal_registration,"register_address"],
                            [seal_registration_request,"register_address"],
                            [childcare_allowance_certificate,"applicant_address"],
                            [pediatric_medical_certificate1,"father_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"],
                            [seal_registration,"register_address_chouban"],
                            [seal_registration_request,"register_address_chouban"],
                            [childcare_allowance_certificate,"applicant_address_chouban"],
                            [pediatric_medical_certificate1,"father_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"],
                            [seal_registration,"register_address_building"],
                            [seal_registration_request,"register_address_building"],
                            [childcare_allowance_certificate,"applicant_address_building"],
                            [pediatric_medical_certificate1,"father_address_building"]
                        );
                        
                        seal2seal();
                        childcare2pediatric();

                        break;
                    }
                    //国外転入(日本人)
                    case "6":{
                        forceSetValue(pediatric_medical_certificate1,"document_detail","move");
                        //引っ越し後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"],
                            [seal_registration,"register_zipcode"],
                            [seal_registration_request,"register_zipcode"],
                            [childcare_allowance_certificate,"applicant_zipcode"],
                            [pediatric_medical_certificate1,"father_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"],
                            [seal_registration,"register_address"],
                            [seal_registration_request,"register_address"],
                            [childcare_allowance_certificate,"applicant_address"],
                            [pediatric_medical_certificate1,"father_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"],
                            [seal_registration,"register_address_chouban"],
                            [seal_registration_request,"register_address_chouban"],
                            [childcare_allowance_certificate,"applicant_address_chouban"],
                            [pediatric_medical_certificate1,"father_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"],
                            [seal_registration,"register_address_building"],
                            [seal_registration_request,"register_address_building"],
                            [childcare_allowance_certificate,"applicant_address_building"],
                            [pediatric_medical_certificate1,"father_address_building"]
                        );
                        
                        seal2seal();
                        childcare2pediatric();

                        break;
                    }
                    //在留資格変更
                    case "8":{
                        forceSetValue(pediatric_medical_certificate1,"document_detail","other");
                        //引っ越し後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"],
                            [seal_registration,"register_zipcode"],
                            [seal_registration_request,"register_zipcode"],
                            [childcare_allowance_certificate,"applicant_zipcode"],
                            [pediatric_medical_certificate1,"father_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"],
                            [seal_registration,"register_address"],
                            [seal_registration_request,"register_address"],
                            [childcare_allowance_certificate,"applicant_address"],
                            [pediatric_medical_certificate1,"father_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"],
                            [seal_registration,"register_address_chouban"],
                            [seal_registration_request,"register_address_chouban"],
                            [childcare_allowance_certificate,"applicant_address_chouban"],
                            [pediatric_medical_certificate1,"father_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"],
                            [seal_registration,"register_address_building"],
                            [seal_registration_request,"register_address_building"],
                            [childcare_allowance_certificate,"applicant_address_building"],
                            [pediatric_medical_certificate1,"father_address_building"]
                        );

                        seal2seal();
                        childcare2pediatric();

                        break;
                    }
                    //世帯合併
                    case "9":{
                        //手続き後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"]
                        );
                        break;
                    }
                    //世帯主変更
                    case "10":{
                        //手続き後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"]
                        );
                        break;
                    }
                    //世帯分離
                    case "11":{
                        forceSetValue(pediatric_medical_certificate1,"document_detail","other");
                        //引っ越し後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"],
                            [childcare_allowance_certificate,"applicant_zipcode"],
                            [pediatric_medical_certificate1,"father_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"],
                            [childcare_allowance_certificate,"applicant_address"],
                            [pediatric_medical_certificate1,"father_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"],
                            [childcare_allowance_certificate,"applicant_address_chouban"],
                            [pediatric_medical_certificate1,"father_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"],
                            [childcare_allowance_certificate,"applicant_address_building"],
                            [pediatric_medical_certificate1,"father_address_building"]
                        );

                        childcare2pediatric();

                        break;
                    }
                    //世帯変更
                    case "12":{
                        //手続き後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"]
                        );
                        break;
                    }
                    //住所設定
                    case "13":{
                        seal2seal();
                        //引っ越し後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"],
                            [seal_registration,"register_zipcode"],
                            [seal_registration_request,"register_zipcode"],
                            [childcare_allowance_certificate,"applicant_zipcode"],
                            [pediatric_medical_certificate1,"father_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"],
                            [seal_registration,"register_address"],
                            [seal_registration_request,"register_address"],
                            [childcare_allowance_certificate,"applicant_address"],
                            [pediatric_medical_certificate1,"father_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"],
                            [seal_registration,"register_address_chouban"],
                            [seal_registration_request,"register_address_chouban"],
                            [childcare_allowance_certificate,"applicant_address_chouban"],
                            [pediatric_medical_certificate1,"father_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"],
                            [seal_registration,"register_address_building"],
                            [seal_registration_request,"register_address_building"],
                            [childcare_allowance_certificate,"applicant_address_building"],
                            [pediatric_medical_certificate1,"father_address_building"]
                        );
                        childcare2pediatric();
                        break;
                    }
                    //転出取消
                    case "14":{
                        forceSetValue(pediatric_medical_certificate1,"document_detail","other");
                        //引っ越し後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"],
                            [childcare_allowance_certificate,"applicant_zipcode"],
                            [pediatric_medical_certificate1,"father_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"],
                            [childcare_allowance_certificate,"applicant_address"],
                            [pediatric_medical_certificate1,"father_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"],
                            [childcare_allowance_certificate,"applicant_address_chouban"],
                            [pediatric_medical_certificate1,"father_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"],
                            [childcare_allowance_certificate,"applicant_address_building"],
                            [pediatric_medical_certificate1,"father_address_building"]
                        );

                        childcare2pediatric();
                        
                        break;
                    }
                    //転出証明書再発行
                    case "15":{
                        //何も同期しない
                        break;
                    }
                    //転入未届
                    case "16":{
                        forceSetValue(pediatric_medical_certificate1,"document_detail","move");
                        //引っ越し後の住所
                        sync(
                            [moving,"new_zipcode"],
                            [residents_certificate,"listed_zipcode"],
                            [seal_registration,"register_zipcode"],
                            [seal_registration_request,"register_zipcode"],
                            [childcare_allowance_certificate,"applicant_zipcode"],
                            [pediatric_medical_certificate1,"father_zipcode"]
                        );
                        sync(
                            [moving,"new_address"],
                            [residents_certificate,"listed_address"],
                            [seal_registration,"register_address"],
                            [seal_registration_request,"register_address"],
                            [childcare_allowance_certificate,"applicant_address"],
                            [pediatric_medical_certificate1,"father_address"]
                        );
                        sync(
                            [moving,"new_address_chouban"],
                            [residents_certificate,"listed_address_chouban"],
                            [seal_registration,"register_address_chouban"],
                            [seal_registration_request,"register_address_chouban"],
                            [childcare_allowance_certificate,"applicant_address_chouban"],
                            [pediatric_medical_certificate1,"father_address_chouban"]
                        );                    
                        sync(
                            [moving,"new_address_building"],
                            [residents_certificate,"listed_address_building"],
                            [seal_registration,"register_address_building"],
                            [seal_registration_request,"register_address_building"],
                            [childcare_allowance_certificate,"applicant_address_building"],
                            [pediatric_medical_certificate1,"father_address_building"]
                        );
                        
                        seal2seal();
                        childcare2pediatric();

                        break;
                    }
                }
            } else {
                //住民異動届を含まない場合
                //窓口に来た人の電話番号（代理人）
                sync(
                    [mynumber,"agent_phone"],
                    [digital_certificate,"agent_phone"]
                );
                //保護者の住所
                sync(
                    [childcare_allowance_certificate,"applicant_zipcode"],
                    [pediatric_medical_certificate1,"father_zipcode"]
                );
                sync(
                    [childcare_allowance_certificate,"applicant_address"],
                    [pediatric_medical_certificate1,"father_address"]
                );
                sync(
                    [childcare_allowance_certificate,"applicant_address_chouban"],
                    [pediatric_medical_certificate1,"father_address_chouban"]
                );
                sync(
                    [childcare_allowance_certificate,"applicant_address_building"],
                    [pediatric_medical_certificate1,"father_address_building"]
                );

                childcare2pediatric();
            }
        }

        //提出日
        sync(
            [moving, "notification_date"],
            [residents_certificate, "notification_date"],
            [seal_registration, "notification_date"],
            [seal_registration_request,"notification_date"],
            [mynumber,"notification_date"],
            [digital_certificate,"notification_date"],
            [childcare_allowance_certificate,"notification_date"],
            [pediatric_medical_certificate1,"notification_date"]
        );
        

        // ■４：異動届の「4.届出人の情報」の「電話番号」を下記へ反映
        sync(
            [moving,"applicant_phone"],
            [residents_certificate,"applicant_phone"]
        );
        // ■５：【7.小児医療交付申請書】の「3.申請者の情報」の名前を下記へ反映
        sync(
            [childcare_allowance_certificate,"applicant_name"],
            [pediatric_medical_certificate1,"father_name"]
        );
        //■６：【7.小児医療交付申請書】の「3.申請者の情報」のフリガナを下記へ反映
        sync(
            [childcare_allowance_certificate,"applicant_kana"],
            [pediatric_medical_certificate1,"father_kana"]
        );
        // ■７：【7.小児医療交付申請書】の「3.申請者の情報」の生年月日を下記へ反映
        sync(
            [childcare_allowance_certificate,"birthdate"],
            [pediatric_medical_certificate1,"father_birthdate"]
        );
        //■８：【7.小児医療交付申請書】の「4.配偶者の情報」の名前を下記へ反映
        sync(
            [childcare_allowance_certificate,"partner_name"],
            [pediatric_medical_certificate1,"mother_name"]
        );
        //■９：【7.小児医療交付申請書】の「4.配偶者の情報」のフリガナを下記へ反映
        sync(
            [childcare_allowance_certificate,"partner_kana"],
            [pediatric_medical_certificate1,"mother_kana"]
        );
        //■１０：【7.小児医療交付申請書】の「4.配偶者の情報」の生年月日を下記へ反映
        sync(
            [childcare_allowance_certificate,"partner_birthdate"],
            [pediatric_medical_certificate1,"mother_birthdate"]
        );
        //■１１：【7.小児医療交付申請書】の「4.配偶者の情報」の電話番号を下記へ反映
        sync(
            [childcare_allowance_certificate,"partner_phone"],
            [pediatric_medical_certificate1,"mother_phone"]
        );
        //■１２：【7.小児医療交付申請書】の「5.お子さんの情報」の名前を下記へ反映
        sync(
            [childcare_allowance_certificate,"child_name_1"],
            [pediatric_medical_certificate1,"child_name_1"]
        );
        sync(
            [childcare_allowance_certificate,"child_name_2"],
            [pediatric_medical_certificate1,"child_name_2"]
        );
        sync(
            [childcare_allowance_certificate,"child_name_3"],
            [pediatric_medical_certificate1,"child_name_3"]
        );
        //■１３：【7.小児医療交付申請書】の「5.お子さんの情報」のフリガナを下記へ反映
        sync(
            [childcare_allowance_certificate,"child_kana_1"],
            [pediatric_medical_certificate1,"child_kana_1"]
        );
        sync(
            [childcare_allowance_certificate,"child_kana_2"],
            [pediatric_medical_certificate1,"child_kana_2"]
        );
        sync(
            [childcare_allowance_certificate,"child_kana_3"],
            [pediatric_medical_certificate1,"child_kana_3"]
        );
        //■１４：【7.小児医療交付申請書】の「5.お子さんの情報」の生年月日を下記へ反映
        sync(
            [childcare_allowance_certificate,"child_birthdate_1"],
            [pediatric_medical_certificate1,"child_birthdate_1"]
        );
        sync(
            [childcare_allowance_certificate,"child_birthdate_2"],
            [pediatric_medical_certificate1,"child_birthdate_2"]
        );
        sync(
            [childcare_allowance_certificate,"child_birthdate_3"],
            [pediatric_medical_certificate1,"child_birthdate_3"]
        );


        if(mynumber){
            const type_of_procedure = findFieldInput(mynumber,"type_of_procedure");
            const parent = type_of_procedure.element.parentNode;
            const alert = document.createElement('p');
            alert.classList.add("injection-alert");
            alert.innerHTML = `
            ※住民基本台帳用の暗証番号<br>お引越しの手続きに伴い必要となる、個人番号カードの住所・氏名等の変更手続きの際などに使用する暗証番号です。<br><br>※券面事項入力補助用の暗証番号<br>個人番号カードに登録されている、個人番号や基本4情報（住所、氏名、生年月日、性別）を確認・利用する際に使用する暗証番号です。
            `;
            parent.insertBefore(alert, type_of_procedure.element.nextSibling);
        }
        if(moving){
            const lost_move_certificate = findFieldInput(moving,"lost_move_certificate");
            const parent = lost_move_certificate.element.parentNode;
            const alert = document.createElement('p');
            parent.insertBefore(alert, lost_move_certificate.element.nextSibling);
            lost_move_certificate.virtualInput.addEventListener("change",()=>{
                if(lost_move_certificate.value === "yes"){
                    alert.classList.add("injection-alert2");
                    alert.innerHTML = `窓口に転出証明書を一緒にご提出ください。`;
                } else if(lost_move_certificate.value === "no"){
                    alert.classList.add("injection-alert2");
                    alert.innerHTML = `転出証明書が出てきた場合、横須賀市へ返却してください。`;
                } else {
                    alert.classList.remove("injection-alert2");
                    alert.innerHTML = "";
                }
            });
        }
        if(childcare_allowance_certificate){
            sync(
                [childcare_allowance_certificate,"account_name_kana"],
                [childcare_allowance_certificate,"applicant_kana"]
            );
        }
    },
    extraPages: [

    ],
    // static show2(message: string | { message: string, okButtonLabel: string }, callback: (boolean) => void = null): Notifier {

    onFinishPreview: () => {
        Notifier.show2({ 
            message: '<span style="display:block;">窓口に提出する申請書の作成はお済みですか？<br>\
            これから作成する場合は、フォーム画面を閉じて、以下のボタンが表示されている画面に戻って、ボタンを押してください。\
            こども青少年給付課に提出する申請書のフォームが新しく立ち上がります。</span>\
            <br><img src="res/yokosuka/orange_button3.png" style="display:block;width:100%;"><br>\
            <span style="display:block">※このフォーム画面を閉じると、入力した内容が消えてしまうため、必ず「保存する」を押してQRコードを保存してください。</span>',
            okButtonLabel: '上記内容を確認した'});
    },
};
