import { RadioElementDefinition } from "../../../src/DigitalForm/definition/element/RadioElementDefinition";
import { FormViewControllerProps } from "../../../src/main";

export const qrCodePageBuildPostProcess: FormViewControllerProps["options"]["callbacks"]["onQRCodePageBuilt"] = (viewController, page, param) => {
  if (param.formDefinition.id === "moving") {
    const procedureTypeBoxDefinition = param.formDefinition.boxes.filter(box => box.id === "procedure_type_box")[0];
    if (!procedureTypeBoxDefinition) throw new Error(`box definition not found: "procedure_type_box"`);
    const procedureTypeDefinition = procedureTypeBoxDefinition.elements.filter(e => e.id === "procedure_type")[0] as RadioElementDefinition;
    if (!procedureTypeDefinition) throw new Error(`element definition not found: "procedure_type`);
    const procedureType = param.rawData.procedure_type;
    const selectedOption = procedureTypeDefinition.options
      .map(option => (typeof option === "string" ? { label: option, value: option } : option))
      .filter(option => option.value === procedureType)[0];
    if (selectedOption) {
      const reasonElement = page.contentDocument.querySelector(`[data-name="reason"]`);
      reasonElement.textContent = `${selectedOption.label}`;
    }
  }
}