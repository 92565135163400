interface Point {
    x: number
    y: number
    pressure: number
}

export const penTypes = [
    "direct",
    "stylus",
    "mouse"
] as const;

export type PenType = typeof penTypes[number];

export const penTypeSetAll = new Set(penTypes);

export type PenTypeSet = typeof penTypeSetAll;

export const ToolType = {
    Pen: 'pen',
    Eraser: 'eraser'
} as const;
export type ToolType = typeof ToolType[keyof typeof ToolType];

interface ISignatureRenderer {
    setTool(tool: ToolType): void
    begin(p: Point): void
    end(): void
    draw(p: Point): void
    clear(): void
    export(mimeType: string): any
    save(): void;
    restore(): void;
}

export class SignatureBoard {
    static readonly PenTypeAll = penTypeSetAll;

    private view: HTMLElement
    private rednerer: ISignatureRenderer
    private penType: PenTypeSet
    private isMouseDown: boolean
    private isRendering: boolean

    public enabled: boolean;

    constructor(rootElm: HTMLElement, renderer: ISignatureRenderer, penType: PenTypeSet = SignatureBoard.PenTypeAll) {
        this.view = rootElm;
        this.rednerer = renderer;
        this.penType = penType;
        this.isMouseDown = false;
        this.isRendering = false;
        this.enabled = true;
        
        this.view.addEventListener('touchstart', this.onTouchStart.bind(this));
        this.view.addEventListener('touchmove', this.onTouchMove.bind(this));
        this.view.addEventListener('touchend', this.onTouchEnd.bind(this));

        if (this.penType.has("mouse")) {
            this.view.addEventListener('mousedown', (e) => {
                this.isMouseDown = true;
                this.onTouchStart(e);
            });
            this.view.addEventListener('mousemove', (e) => {
                if (this.isMouseDown) {
                    this.onTouchMove(e);
                }
            });
            this.view.addEventListener('mouseup', (e) => {
                this.isMouseDown = false;
                this.onTouchEnd(e);
            });
        }
    }

    setTool(tool: ToolType) {
        this.rednerer.setTool(tool);
    }

    clear() {
        this.rednerer.clear();
    }

    save() {
        this.rednerer.save();
    }

    restore() {
        this.rednerer.restore();
    }

    export(mimeType: string = 'image/png') {
        return this.rednerer.export(mimeType);
    }

    onTouchStart(e: any) {
        const p = this.getPoint(e);

        this.isRendering = this.enabled && !!p;

        if (this.isRendering) {
            this.rednerer.begin(p!);
        }

        e.preventDefault();
        e.stopPropagation();
    }

    onTouchMove(e: any) {
        if (this.isRendering) {
            const p = this.getPoint(e);
            if (p) {
                this.rednerer.draw(p!);
            }
        }

        e.preventDefault();
        e.stopPropagation();
    }

    onTouchEnd(e: any) {
        if (this.isRendering) {
            this.isRendering = false;
            this.rednerer.end();
        }

        e.preventDefault();
        e.stopPropagation();
    }

    getPoint(e: any): Point | null {
        const target = e.target as HTMLElement;
        const b = target.getBoundingClientRect();

        if (this.isTouchEvent(e)) {
            const touchType = e.touches[0].touchType;
            if ((this.penType.has("stylus") && touchType === 'stylus') ||
                (this.penType.has("direct") && touchType !== 'stylus')) {
                let pressure = e.touches[0]['force'];
                return {
                    pressure: pressure > 0 ? pressure : 0.1,
                    x: e.touches[0].pageX - b.x,
                    y: e.touches[0].pageY - b.y
                };
            }
        } else {
            return {
                pressure: 0.1,
                x: e.pageX - b.x,
                y: e.pageY - b.y
            }
        }

        return null;
    }

    isTouchEvent(e: any): boolean {
        return e.touches && e.touches[0] && typeof e.touches[0]['force'] !== undefined;
    }
}

interface CanvasSignatureRendererInput {
    canvas: HTMLCanvasElement
    color?: string
    lineWidth?: number
    eraserSize?: number
}

export class CanvasSignatureRenderer implements ISignatureRenderer {

    private canvas: HTMLCanvasElement
    private context: CanvasRenderingContext2D | null
    private points: Point[]
    private color: string
    private lineWidth: number
    private eraserSize: number
    private tool: ToolType
    private savedData: ImageData;

    constructor({ canvas, color = 'black', lineWidth = 30, eraserSize = 10 }: CanvasSignatureRendererInput) {
        this.canvas = canvas;
        this.context = this.canvas.getContext('2d');
        this.points = [];
        this.color = color;
        this.lineWidth = lineWidth;
        this.eraserSize = eraserSize;
        this.tool = ToolType.Pen;
    }

    setTool(tool: ToolType) {
        this.tool = tool;
    }

    begin(p: Point) {
        const context = this.context!;

        this.points = [p];
        context.lineCap = 'round';
        context.lineWidth = p.pressure * this.lineWidth;
        context.strokeStyle = this.color;
        context.fillStyle = this.color;
        
        if (this.tool == ToolType.Pen) {
            context.globalCompositeOperation = 'source-over';
        } else {
            context.globalCompositeOperation = 'destination-out';
            context.arc(p.x, p.y, this.eraserSize * 0.5, 0, 2 * Math.PI);
            context.fill();
        }
    }

    end() {

    }

    draw(p: Point) {
        this.points.push(p);

        if (this.tool == ToolType.Pen) {
            this.drawPen();
        } else {
            this.drawEraser();
        }
    }

    drawPen() {
        const context = this.context!;
        if (this.points.length < 3) {
            return;
        }

        const i = this.points.length - 1;

        const p0 = this.points[i-2];
        const p1 = this.points[i-1];
        const p2 = this.points[i];

        const pressure = (p0.pressure + p1.pressure + p2.pressure) * 0.33;

        context.lineWidth = pressure * this.lineWidth;
        context.beginPath();
        context.moveTo((p0.x + p1.x) * 0.5, (p0.y + p1.y) * 0.5);
        context.quadraticCurveTo(p1.x, p1.y, (p1.x + p2.x) * 0.5, (p1.y + p2.y) * 0.5);
        context.stroke();
    }

    drawEraser() {
        const context = this.context!;
        context.lineWidth = this.eraserSize;
        const i = this.points.length;
        const p0 = this.points[i-2];
        const p1 = this.points[i-1];
        context.beginPath();
        context.moveTo(p0.x, p0.y);
        context.lineTo(p1.x, p1.y);
        context.stroke();
    }

    clear() {
        this.canvas.width = this.canvas.width;
        this.context = this.canvas.getContext('2d');
    }

    export(mimeType: string = 'image/png'): any {
        return this.canvas.toDataURL(mimeType);
    }

    private randcol(): string {
        const r = (Math.random() * 255)|0;
        const g = (Math.random() * 255)|0;
        const b = (Math.random() * 255)|0;
        return `rgb(${r},${g},${b})`;
    }

    save() {
        this.savedData = this.context!.getImageData(0, 0, this.canvas.width, this.canvas.height);
    }

    restore() {
        if (this.savedData) {
            this.context!.putImageData(this.savedData, 0, 0);
        } else {
            this.clear();
        }
    }
}